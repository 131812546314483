import './App.css';
import Skills from './components/skills';
import Console from './components/console';
import Image from './components/image';

function App() {
  const profileImage = require("./static/img/portfolio-pic.png");

  return (<><div className="main">
        <div style={{display: "flex", flexDirection: "row", gap:"1em"}}>
          <Image image={profileImage} width="12rem" alt="Profile"/>
          <Skills/>
          <Console/>
          </div>
  </div> <footer><h1 className="nav item"><span><small>© 2022 -</small></span>&nbsp;Choose Patrick&nbsp;<small>-</small></h1></footer></>);
}

export default App;
