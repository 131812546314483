import './skills.css';
import {useEffect, useState} from 'react';

const Skills = () => {

  const [showToolTip, setShowToolTip] = useState(false);

  const mouseOverHandler = ()=>{
    console.log('mouseOver start: ', showToolTip)
    setShowToolTip( !showToolTip );
    console.log('mouseOver end: ', showToolTip)
  }

  useEffect(()=>{

  },[showToolTip])

  return (<div id="skillbox">
    
          {/* <label className='tooltip' htmlFor="front-end">
            <p><span onMouseOver={mouseOverHandler} className='green'>.current</span></p>
            <span className="tooltip-content">What I currently use and have experience with</span>
          </label> */}
          <ul name="front-end">
              <li>frontEnd:</li>
              <li>html/css/js</li>
              <li>React.js</li>
          </ul>
          <ul name="back-end">
              <li>backEnd:</li>
              <li>mySQL</li>
              <li>MongoDB</li>
              <li></li>
          </ul>
          <ul name="languages">
              <li>languages:</li>
              <li>Java</li>
              <li>Python</li>
              <li>Javascript</li>
          </ul>

  </div>);
}

export default Skills;