
const Image=(props)=>{

  const style = {
    width: props.width,
    height: "auto",
    margin: "0 auto"
  }

  return (<>
    <img style={style} src={props.image} alt={props.alt}/>
  </>);
}

export default Image;