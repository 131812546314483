import './console.css';
import {useState, useEffect} from 'react';

const Console=()=>{

  const [inputBuffer, setInputBuffer] = useState('');
  const [inputHistory, setInputHistory] = useState([]);
  const basePrompt = `Options:\n\n contact about projects history\n\nReady.\n`;
  const isAndroid = navigator.userAgent.toLowerCase().match(/android/i);
    
  const [prompt, setPrompt] = useState(basePrompt);
  const maxLength = 64;
  const maxHistory = 4;
  const blinkRate = 500;
  const caret = '█';

  const output=(outputStr='output')=>{

    let delay = (outputStr.length * 100);
    if (delay < 1000)
      delay = 1000;
    if (Array.isArray(outputStr))
      delay = outputStr.length * 1000;
    setPrompt(outputStr);
    if (delay > 4500)
      delay = 4500;
    let timeoutId = setTimeout(()=>{
      setPrompt(basePrompt);
    },delay)
  }

  const history=()=>{
    let outputStr = '';
    for (let each in inputHistory)
      outputStr += inputHistory[each];
    output(outputStr);
    // return this
  }
  const email=()=>{
    output('Contact form coming soon! Reach me at:\n\nchoosePatrick@gmail.com')
    // return this
  }
  const about=()=>{
    output('I like building gadgets.')
    // return this
  }
  const projects=()=>{
    output('Coming soon!')
  }

  const inputOptions={
    'history': history,
    'email': email,
    'contact': email,
    'about': about,
    'output': output,
    'projects': projects
  }

  const parse=(input)=>{

    input = input.toLowerCase();
    let date = new Date();
    if (inputHistory.length > maxHistory) {
      let temp = [...inputHistory];
      temp.shift();
      setInputHistory(temp);
    }

    setInputBuffer('');

    if (inputOptions[input]) {
      inputOptions[input]();
      setInputHistory([...inputHistory, input+' @ '+date.toLocaleTimeString()+'\n']);
    } else {
      output(`I can't do that, Dave.`);
    }
  }

  useEffect(()=>{
    const terminal = document.getElementById("input");
    if (inputBuffer === '') {
      terminal.value = prompt;
    }

  },[inputBuffer, prompt, caret]);

  const inputHandler=(e)=>{

    if (e.target.selectionStart < prompt.length){
      e.target.selectionStart = prompt.length+1;
    }

    if (e.key === "Enter") {
      e.preventDefault();

      if (inputBuffer !== '') 
        parse(inputBuffer);
    }

    if ( (e.key === 'Backspace')  ) {
      // e.preventDefault();
      setInputBuffer(inputBuffer.slice(0,-1));
      if ((e.target.value.length <= prompt.length || inputBuffer === '')) {
        e.preventDefault();
        e.target.value=prompt;
      }
    } else if ( e.key && e.key.length === 1) {
      setInputBuffer(inputBuffer+e.key);
    }
  }

  const clickHandler=(e)=>{
    e.preventDefault();
    e.target.focus();
    // if (e.target.selectionStart < 8)
    //   e.target.selectionStart = 8;
  }

  return (<div className="console-container">
  
  <div className='console'>
    <div className="crt">
      <form>
        <textarea onFocus={inputHandler} onMouseDown={clickHandler} onKeyDown={inputHandler} cols="36" rows="18" id="input" maxLength={maxLength+prompt.length} spellCheck="false"/>
      </form>
    </div>
  </div>
  </div>)};

export default Console;